import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export enum CustomerType {
  NEW = "new",
  EXISTING = "existing",
}

interface FormState {
  step: number;
  customerType: CustomerType | null;
  customerId: string;
}

const initialState: FormState = {
  step: 1,
  customerType: null,
  customerId: "",
};

export const FormSlice = createSlice({
  name: "Form",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setCustomerType: (state, action: PayloadAction<CustomerType>) => {
      state.customerType = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
  },
});

/*===============================
            Actions
==============================*/

export const { setStep, setCustomerType, setCustomerId } = FormSlice.actions;

/*===============================
           Selectors
==============================*/

export const selectStep = (state: RootState) => state.form.step;
export const selectCustomerType = (state: RootState) => state.form.customerType;
export const selectCustomerId= (state: RootState) => state.form.customerId;

export default FormSlice.reducer;
