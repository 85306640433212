import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectStep,
  setCustomerId,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import "./CustomerOnboard.scss";
import {
  CustomerDeploymentApi,
  CustomerOnboradProps,
} from "../../utils/CustomerDeploymentApi/CustomerDeploymentApi";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";

export const CustomerOnboard: React.FC = () => {
  const instanceType = ["Metro Line", "Train Line", "Airport", "Office"];
  const [error, setError] = useState<Error>();
  const [customerData, setCustomerData] = React.useState<CustomerOnboradProps>({
    name: "",
    salesforce_id: "",
    description: "",
    instance_type: "",
  });
  const step = useAppSelector(selectStep);
  const dispatch = useAppDispatch();
  const mutation = CustomerDeploymentApi.useCreateCustomer();
  const token = useAppSelector(selectAuthTokens);
  /**
   * Go to Customer config
   * @param event
   */
  const handleNext = () => {
    dispatch(setStep(step + 1));
  };

  const handleSubmit = () => {
    mutation.mutate({ customer: customerData, token: token!.id_token });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      dispatch(setCustomerId(mutation.data.customer_id));
      handleNext();
    }
    if (mutation.isError) {
      setError(mutation.error as Error);
    }
  }, [mutation.isSuccess, mutation.data, mutation.error]);

  return (
    <div className="container">
      <h2>Onboard New Customer</h2>
      <div className="input-value">
        <label>Customer Name:</label>
        <input
          type="text"
          placeholder="Name"
          onChange={(e) =>
            setCustomerData({ ...customerData, name: e.target.value })
          }
        />
      </div>
      <div className="input-value">
        <label>Saleforce id:</label>
        <input
          type="text"
          placeholder="id"
          onChange={(e) =>
            setCustomerData({ ...customerData, salesforce_id: e.target.value })
          }
        />
      </div>
      <div className="input-value">
        <label>Description:</label>
        <input
          type="text"
          placeholder="..description"
          onChange={(e) =>
            setCustomerData({ ...customerData, description: e.target.value })
          }
        />
      </div>
      <div className="select-value">
        <label>Instance Type:</label>
        <select
          className="dropdown"
          value={customerData.instance_type}
          onChange={(e) =>
            setCustomerData({ ...customerData, instance_type: e.target.value })
          }
        >
          <option value="" disabled>
            Select Instance Type
          </option>
          {instanceType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="Buttons">
        <button className="button" onClick={handleSubmit}>
          Create Customer
        </button>
      </div>
      {mutation.isLoading && <span>Creating Customer.....</span>}
      {mutation.isError && <span>{error?.message}</span>}
    </div>
  );
};
