import { useMutation } from "react-query";

/**
 * This file holds all the api calls for customer deployment api
 */
export interface CustomerOnboradProps {
  name: string;
  salesforce_id: string;
  description: string;
  instance_type: string;
}

/**
 * API URL
 */
const apiUrl = process.env.REACT_APP_CUSTOMER_DEPLOYMENT_INVOKE_URL;

/**
 * Query names
 */
const queryNames = {
  CREATECUSTOMR: "/customer",
  GETALLCUSTOMER: "/getAllCustomer",
  GETCUSTOMERBYID: "/customer",
};

/**
 * Create a new customer
 * Method: POST
 * @param customer
 * @param token
 */
const createCustomer = async (
  customer: CustomerOnboradProps,
  token: string
) => {
  const response = await fetch(`${apiUrl}${queryNames.CREATECUSTOMR}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(customer),
  });
  if (!response.ok) {
    const errorText = await response.json();
    throw new Error(`Error: ${errorText.message}`);
  }

  return response.json();
};

/**
 *
 * @returns mutation for creation of customer in dynamodb
 */
const useCreateCustomer = () => {
  return useMutation(
    ({ customer, token }: { customer: CustomerOnboradProps; token: string }) =>
      createCustomer(customer, token)
  );
};

/**
 * @returns All the customers present in dynamodb for customer deployment
 * @param token
 * @TODO change url later
 */
const getAllCustomers = async () => {
  const response = await fetch(
    `https://mej3wmyki2.execute-api.eu-west-1.amazonaws.com/dev/customers`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // "Authorization": `Bearer ${token}`
      },
    }
  );
  if (!response.ok) {
    const errorText = await response.json();
    throw new Error(`Error: ${errorText.message}`);
  }
  return response.json();
};

/**
 *
 * @param customerId
 * @param token
 * @returns Customer details for specific customer
 */
const getCustomerDetailsById = async (customerId: string, token: string) => {
  const response = await fetch(
    `${apiUrl}${queryNames.GETCUSTOMERBYID}/${customerId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    const errorText = await response.json(); 
    throw new Error(`Error: ${errorText.message}`);
  }

  return response.json();
};

/**
 * Export all the api calls
 */
export const CustomerDeploymentApi = {
  queryNames,
  createCustomer,
  useCreateCustomer,
  getAllCustomers,
  getCustomerDetailsById,
};
