// src/components/MultiStepForm.tsx
import React from "react";
import "./DeploymentForm.scss";
import MainPage from "../MainPage";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CustomerType,
  selectCustomerType,
  selectStep,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import UpdateCustomer from "../UpdateCustomerPage/UpdateCustomer";
import { CustomerOnboard } from "../CustomerOnboardPage/CustomerOnboard";
import { SelectInstaceType } from "../SelectInstance/SelectInstanceType";
import BackButton from "../UIComponent/BackButton/BackButton";

const DeploymentForm: React.FC = () => {
  const step = useAppSelector(selectStep);
  const customerType = useAppSelector(selectCustomerType);

  const dispatch = useAppDispatch();

  const handlePrevious = () => {
    // handle back button click
    dispatch(setStep(step - 1));
  };

  return (
    <div className="form-container">
      {step !== 1 && <BackButton onClick={handlePrevious} />}
      {step === 1 && <MainPage />}
      {step === 2 && customerType === CustomerType.EXISTING && (
        <UpdateCustomer />
      )}
      {step === 2 && customerType === CustomerType.NEW && <CustomerOnboard />}
      {step === 3 && <SelectInstaceType />}
    </div>
  );
};

export default DeploymentForm;