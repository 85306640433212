import React from "react";
import "./App.scss";
import LoginRoute from "./routes/LoginRoute/LoginRoute";
import { Redirect, Route } from "wouter";
import LoginCallback from "./routes/LoginRoute/LoginCallBack";
import ProtectedRoute from "./routes/protected/ProtectedRoute";
import LogoutRoute from "./components/Logout/LogoutRoute";
import { TopNavBar } from "./components/TopNavbar/TopNavbar";
import { MainSideBar } from "./components/MainSideBar/MainSidebar";
import { CustomerOnboard } from "./components/CustomerOnboardPage/CustomerOnboard";
import UpdateCustomer from "./components/UpdateCustomerPage/UpdateCustomer";
import DeploymentForm from "./components/DeploymentForm/DeploymentForm";

//Layout for side-bar and and top nav-bar
const MainSidebarWrapper: React.FC<{
  children: JSX.Element;
  domain: string;
}> = (props) => {
  return (
    <>
      <TopNavBar />
      <div id="main-container">
        <MainSideBar domain={props.domain} />
        <div className="main-view-container">{props.children}</div>
      </div>
    </>
  );
};

const App: React.FC = () => {
  return (
    <div className="App">
      <Route path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login">
        <LoginRoute />
      </Route>
      <Route path="/login-callback">
        <LoginCallback />
      </Route>
      {/* <ProtectedRoute path="/customer-onboard">
        <MainSidebarWrapper domain="OnboardCustomer">
          <CustomerOnboard />
        </MainSidebarWrapper>
      </ProtectedRoute>
      <ProtectedRoute path="/update-customer">
        <MainSidebarWrapper domain="UpdateCustomer">
          <UpdateCustomer />
        </MainSidebarWrapper>
      </ProtectedRoute> */}
      <ProtectedRoute path="/multi-form">
        <MainSidebarWrapper domain="MultiForm">
          <DeploymentForm />
        </MainSidebarWrapper>
      </ProtectedRoute>
      <ProtectedRoute path="/logout">
        <LogoutRoute />
      </ProtectedRoute>
    </div>
  );
};

export default App;
