import React from "react";
import KoneBrandMark from "../KoneBrandMark";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import "./topNavbar.scss";
import { ControlledMenu, MenuItem, useClick } from "@szhsin/react-menu";
import { RiLogoutBoxLine } from "react-icons/ri";
import { User, selectUser } from "../../features/authorization/AuthSlice";
import { useAppSelector } from "../../app/hooks";

export const TopNavBar: React.FC = () => {
  const userContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const anchorProps = useClick(isMenuOpen, setIsMenuOpen);

  const userDetails: User | undefined = useAppSelector(selectUser);

  const logout = () => {
    // Todo add function for logout
    console.log("clicked logout");
  };

  return (
    <nav id="top-navbar">
      <div>
        <KoneBrandMark />
        Station Twin Deployment
      </div>
      <div
        className="username-container"
        ref={userContainerRef}
        {...anchorProps}
      >
        <div className="user-icon">
          <UserIcon fill="#717A80" />
          <div className="user-status active"></div>
        </div>
        <span>{userDetails ? userDetails.userEmail : "--"}</span>
        <div className={"expand" + (isMenuOpen ? " rotate" : "")}>
          <ChevronDown />
        </div>
      </div>
      <ControlledMenu
        state={isMenuOpen ? "open" : "closed"}
        anchorRef={userContainerRef}
        onClose={() => setIsMenuOpen(false)}
        menuClassName={"menu"}
        align="end"
      >
        <MenuItem onClick={logout} className={"menu-item"}>
          <div
            style={{
              width: userContainerRef.current?.offsetWidth
                ? `${userContainerRef.current?.offsetWidth}px`
                : "140px",
            }}
            className="menu-item-content"
          >
            <span className="icon">
              <RiLogoutBoxLine />
            </span>
            <span>Log out</span>
          </div>
        </MenuItem>
      </ControlledMenu>
    </nav>
  );
};
