import React from "react";
import "./mainSidebar.scss";
import { FiFastForward } from "react-icons/fi";
import { ReactComponent as LeftSideArrow } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as OfficeIcon } from "../../assets/icons/office-building-2.svg";
import {
  selectMainSidebarStatus,
  setMainSideBarStatus,
} from "../../features/sidebar/SideBarSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLocation } from "wouter";

interface MainSideBarProps {
  domain: String;
}

export const MainSideBar: React.FC<MainSideBarProps> = (props) => {
  const dispatch = useAppDispatch();
  const isFull = useAppSelector(selectMainSidebarStatus);

  const [, setLocation] = useLocation();

  return (
    <div className={"mainSidebar " + (isFull ? "" : "reduced")}>
      <nav>
        <ul>
          {/* <li className={props.domain === "OnboardCustomer" ? "active" : ""}>
            <div
              className="main-sidebar-element"
              onClick={() => setLocation("/customer-onboard")}
            >
              <span className="icon">
                <OfficeIcon />
              </span>
              {isFull ? <span>Onboard New Customer</span> : null}
            </div>
          </li>
          <li className={props.domain === "UpdateCustomer" ? "active" : ""}>
            <div
              className="main-sidebar-element"
              onClick={() => setLocation("/update-customer")}
            >
              <span className="icon">
                <FiFastForward />
              </span>
              {isFull ? <span>Change Existing Customer</span> : null}
            </div>
          </li> */}
          <li className={props.domain === "MultiForm" ? "active" : ""}>
            <div
              className="main-sidebar-element"
              onClick={() => setLocation("/multi-form")}
            >
              <span className="icon">
                <FiFastForward />
              </span>
              {isFull ? <span>MultiForm</span> : null}
            </div>
          </li>
          <li>
            <div
              className={"reducer " + (isFull ? "" : "reverse")}
              onClick={() => dispatch(setMainSideBarStatus(!isFull))}
            >
              <div style={{ marginRight: "-17px" }}>
                <LeftSideArrow />
              </div>
              <div>
                <LeftSideArrow />
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};
