import React, { useMemo } from "react";
import KoneBrandMark from "../../components/KoneBrandMark";
import "./loginRoute.scss";
import {
  StateStatus,
  selectAuth,
  selectLoggedIn,
  setStatus,
} from "../../features/authorization/AuthSlice";
import { loginSSo } from "../../utils/Authorization";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Redirect } from "wouter";

const LoginContainer: React.FC = () => {
  
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);

  const handleSubmitClick = () => {
    console.log("clicked Login");
    loginSSo();
    dispatch(setStatus(StateStatus.PENDING));
  };

  const buttonClass = useMemo(() => {
    return authState.status === StateStatus.PENDING ? "pending" : "";
  }, [authState]);

  return (
    <div id="login">
      <div id="intro">
        <KoneBrandMark />
      </div>
      <div id="loginWrapper">
        <div id="loginForm">
          <div id="contentWrapper">
            <h1>Customer Deployment</h1>
            <p>
              Smooth and transparent station operations management and
              optimization.
              <br></br>
            </p>
          </div>
          <div id="actions">
            <button className={buttonClass} onClick={handleSubmitClick}>
              <span>Log in with KONE Account</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginRoute: React.FC = () => {
  const authenticated = useAppSelector(selectLoggedIn);
  console.log("auth", authenticated)
  return authenticated ? <Redirect to="/multi-form"/> : <LoginContainer />;
};

export default LoginRoute;
