import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CustomerType,
  selectStep,
  setCustomerType,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import "./style.scss";

const MainPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectStep);

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value: CustomerType = event.currentTarget.value as CustomerType;
    dispatch(setCustomerType(value));
    dispatch(setStep(step + 1));
  };

  return (
    <>
      <div className="stack">
        <h3>People Flow Digital Customer Deployment Portal</h3>
        <button className="button" value="new" onClick={(e) => handleNext(e)}>
          Create New User
        </button>
        <button
          className="button green"
          value="existing"
          onClick={(e) => handleNext(e)}
        >
          Update Existing User
        </button>
      </div>
    </>
  );
};
export default MainPage;
