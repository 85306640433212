import React, { useEffect, useState } from "react";
import "./UpdateCustomer.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectStep,
  setCustomerId,
  setStep,
} from "../../features/multiStepForm/FormSlice";
import { useQuery } from "react-query";
import { CustomerDeploymentApi } from "../../utils/CustomerDeploymentApi/CustomerDeploymentApi";
// import { selectAuthTokens } from "../../features/authorization/AuthSlice";

type CustomerDataType = {
  customer_name: string;
  customer_id: string;
};

const UpdateCustomer: React.FC = () => {
  const { data, isLoading } = useQuery(
    CustomerDeploymentApi.queryNames.GETALLCUSTOMER,
    () => CustomerDeploymentApi.getAllCustomers(),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
    }
  );
  const [selectedValue, setSelectedValue] = useState<string>("");
  const step = useAppSelector(selectStep);
  const dispatch = useAppDispatch();
  // const token = useAppSelector(selectAuthTokens) uncomment when needed


  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedValue(event.target.value);
  };
  
  useEffect(() => {

    dispatch(setCustomerId(selectedValue));
  },[selectedValue]);
  const toCustomerConfig = (buttonType: string) => {
    if (selectedValue === "") {
      alert("Please select a customer");
    } else {
      dispatch(setStep(step + 1));
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="container">
      <div className="select-user">
        <label>Select Customer</label>
        <select
          className={"dropdown"}
          value={selectedValue}
          onChange={handleDropdownChange}
        >
          <option value="" disabled>
            Select a value
          </option>
          {JSON.parse(data.body).map(
            (customer: CustomerDataType, index: number) => (
              <option
                key={index}
                value={customer.customer_id}
              >
                {customer.customer_name}
              </option>
            )
          )}
        </select>
      </div>
      <div className={"buttonContainer"}>
        <button className={"button"} onClick={() => toCustomerConfig("1")}>
          Go to Customer Configuration
        </button>
      </div>
    </div>
  );
};

export default UpdateCustomer;
