import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../features/authorization/AuthSlice";
import SideBarReducer from "../features/sidebar/SideBarSlice";
import FormReducer from "../features/multiStepForm/FormSlice";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
};

const reducer = combineReducers({
  auth: AuthReducer,
  sidebar: SideBarReducer,
  form: FormReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
