import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import {
  CustomerType,
  selectCustomerId,
  selectCustomerType,
} from "../../features/multiStepForm/FormSlice";
import { useQuery } from "react-query";
import { CustomerDeploymentApi } from "../../utils/CustomerDeploymentApi/CustomerDeploymentApi";
import { selectAuthTokens } from "../../features/authorization/AuthSlice";

export const SelectInstaceType: React.FC = () => {
  const customerType = useAppSelector(selectCustomerType);
  const [customerName, setCustomerName] = useState<string>();
  const [customerInstaceType, setCustomerInstanceType] = useState<string[]>([]);
  const [salesforceCustomerId, setsalesforceCustomerId] = useState<string>();
  const fileType = ["GTFS", "zip", "xlsx"];

  // Fetch Customer Details
  const customerId = useAppSelector(selectCustomerId);
  console.log("customer id", customerId);
  const tokens = useAppSelector(selectAuthTokens);
  const { data, isFetching, isSuccess } = useQuery(
    CustomerDeploymentApi.queryNames.GETCUSTOMERBYID,
    () =>
      CustomerDeploymentApi.getCustomerDetailsById(
        customerId!,
        tokens!.id_token
      ),
    {
      refetchOnWindowFocus: false, // Prevent refetching when window regains focus
      refetchOnReconnect: false, // Prevent refetching when reconnecting to the network
      refetchInterval: false, // Disable automatic refetching
      staleTime: 5000,
    }
  );

  useEffect(() => {
    if(isSuccess) {
      setCustomerInstanceType([data[0]["customer_type"]["S"]]);
      setCustomerName(data[0]["customer_name"]["S"]);
      setsalesforceCustomerId(data[0]["customer_salesforce_id"]["S"]);
    }
  },[data, isSuccess])

  if (isFetching) return <div>Loading Details...</div>;

  return ( 
    <div className="container">
      <h3>
        {customerType === CustomerType.NEW ? "New " : ""} Customer:{" "}
        {customerName}({salesforceCustomerId}
        )
      </h3>
      <div className="select-value">
        <label>Instance Type</label>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {customerInstaceType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="select-value">
        <label>Salesforce Customer</label>
        <label>{salesforceCustomerId}</label>
        {/* <select className="dropdown">
          <option value={salesforceCustomerId} disabled>
            {salesforceCustomerId}
          </option>
          {salesforceCustomerId.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select> */}
      </div>
      <div className="select-value">
        <label>Stations Network</label>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {fileType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        {/* TODO: Add code to select folder with upload buton and also show them below inside this div */}
      </div>
      <div className="select-value">
        <label>Metro Api</label>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {fileType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        {/* TODO: Add a input box to accept links  */}
      </div>
      <div className="select-value">
        <label>Weather API</label>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {fileType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {fileType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        {/* TODO: Add a input box to accept links  */}
      </div>
      <div className="select-value">
        <label>Topology Standard</label>
        <select className="dropdown">
          <option value="" disabled>
            Select a value
          </option>
          {fileType.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="Buttons">
        <button
          className="button"
          onClick={() => alert("This Feature will come soon")}
        >
          Update/ Create Customer
        </button>
        <button
          className="button"
          onClick={() => alert("This Feature will come soon")}
        >
          Skip and go to MetroLines
        </button>
      </div>
    </div>
  );
};
